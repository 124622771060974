<template>
  <v-container v-if="isAlertVisible">
    <div class="alert-box">
      <div class="message-container">
        <v-icon>mdi-alert-outline</v-icon>
        <div style="margin: 0px 10px">
          Your company profile is not yet verified, which may limit access to
          certain features. To unlock these features, please request
          verification from Seamless Source.
        </div>
      </div>
      <div>
        <request-verification-button
          @request-verification="requestVerificationForAccount"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import auth from "@/auth";
import notification from "@/notification";
import restAdapter from "@/restAdapter";
import RequestVerificationButton from "@/components/RequestVerificationButton.vue";
import store from "@/store";

export default {
  name: "RouterHeader",
  components: {
    RequestVerificationButton,
  },
  computed: {
    isAlertVisible() {
      return (
        auth.user.account &&
        auth.user.account.organization_registry_id &&
        !auth.user.account.verified
      );
    },
  },
  methods: {
    requestVerificationForAccount() {
      const id = auth.user.account?.organization_registry_id;
      if (!id) {
        return;
      }
      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          store.commit("SET_ACCOUNT_VERIFICATION_REQUESTED", true);
          notification.success(
            "Profile Verification request sent to Seamless Source"
          );
        })
        .catch((error) => {
          notification.error("Something went wrong, please try again later.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: $backgroundColor;
  border: 1px solid $dangerBackground;
  border-radius: 5px;
}
.message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.message-container .v-icon,
.message-container div {
  color: $dangerBackground;
  font-weight: bold;
  text-align: left;
}
</style>
